import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelopeOpenText,
  faMapMarkedAlt,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputBase, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export function Footer() {
  return (
    <div className="container-footer">
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        sx={{ padding: { xs: "50px 20px", md: "100px 204px" } }}
        gap={5}
      >
        <Grid item xs={12} md={3}>
          <div>
            <LazyLoadImage
              className="logo-campus"
              alt="logo-campus"
              src={require("./campus_impact_logo.png").default}
            />
            <p className="text-footer">
              Helping young people from low-income and disadvantaged families
              acquire skills and values to become useful citizens for the
              community and serve as pillars of strength for the nation.
            </p>

            <div className="social-media d-flex justify-content-start">
              <a
                href="https://www.facebook.com/campusimpact.sg"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/campusimpactsg/"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://sg.linkedin.com/company/campusimpact"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7SYmdWdnXUe6hXNllQj2uQ"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="container-sitemap">
            <LazyLoadImage
              className="logo-org"
              alt="logo-org"
              src={require("./organization_logo.png").default}
            />
            <p className="text-footer">
              As the SG Cares Volunteer Centre appointed by the SG Cares Office
              since August 2020, we seek to promote volunteerism through our
              culture of having a positive attitude, purposeful life and
              pioneering spirit.
            </p>

            <div className="social-media d-flex justify-content-start">
              <a
                href="https://www.facebook.com/campusimpact.sg"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/campusimpactsg/"
                className="icon-social"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ marginTop: "30px" }}>
            <p className="title-footer">Sitemap</p>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={5}>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Stack direction="column">
                        <a
                          href="https://www.campusimpact.org.sg/annual-publication/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Annual Publication
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/campus-connect/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Campus Connect
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/celebrate-our-milestones/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Celebrate Our Milestones
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/meet-the-team/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Meet The Team
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/moments-captured/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Moments Captured
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/about-page/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Share The Vision
                        </a>
                      </Stack>
                    </React.Fragment>
                  }
                >
                  <a className="footer-menu" href="#">
                    About <KeyboardArrowDownIcon />
                  </a>
                </HtmlTooltip>
                <a
                  className="footer-menu"
                  target={"_blank"}
                  href="https://www.campusimpact.org.sg/our-campaign/"
                >
                  Donate
                </a>
              </Stack>
              <Box>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Stack direction="column">
                        <a
                          href="https://www.campusimpact.org.sg/get-involved/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Get Involved
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/volunteer-opportunities/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Volunteer Oportunities
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/everyday-santa/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Be An Everyday Santa
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/vc-publications/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          VC Publications
                        </a>
                        <a
                          href="https://www.campusimpact.org.sg/resources-faq/"
                          target="_blank"
                          className="tooltip-link"
                        >
                          Resources & FAQ
                        </a>
                      </Stack>
                    </React.Fragment>
                  }
                >
                  <a className="footer-menu" href="#">
                    SG Cares Volunteer Centre <KeyboardArrowDownIcon />
                  </a>
                </HtmlTooltip>
              </Box>
              <Box>
                <a
                  className="footer-menu"
                  target={"_blank"}
                  href="https://www.campusimpact.org.sg/contact-page/"
                >
                  Contact Us
                </a>
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignItems={"center"}
              sx={{ marginTop: "20px" }}
            >
              <SearchIcon
                style={{
                  fontSize: 34,
                  color: "#1B1F2E",
                  position: "absolute",
                  transform: "translateX(10px)",
                  zIndex: 2,
                }}
              />
              <InputBase className="input-email" placeholder="Search..." />
            </Stack>
          </Box>
          <Box sx={{ marginTop: "15px" }}>
            <p className="title-footer">Contact</p>
            <Stack direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                style={{ fontSize: 20, color: "#EC2935" }}
                icon={faPhoneVolume}
              />
              <p
                className="text-footer"
                style={{ fontSize: "16px", margin: "4px 18px" }}
              >
                +65 6482 1324
              </p>
            </Stack>
            <Stack direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                style={{ fontSize: 20, color: "#EC2935" }}
                icon={faEnvelopeOpenText}
              />
              <p
                className="text-footer"
                style={{ fontSize: "16px", margin: "4px 18px" }}
              >
                enquiries@campusimpact.org.sg
              </p>
            </Stack>
            <Stack direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                style={{ fontSize: 20, color: "#EC2935" }}
                icon={faMapMarkedAlt}
              />
              <p
                className="text-footer"
                style={{ fontSize: "16px", margin: "4px 18px" }}
              >
                Blk 151, #01-26, Yishun Street 11 Singapore 760151
              </p>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <div className="copyright">
        <span style={{ color: "#8d91a2" }}>
          © Copyright 2021 CAMPUS IMPACT. ALL RIGHTS RESERVED.
        </span>
      </div>
    </div>
  );
}
