import React from 'react'
import ImgImpactful from './impactful.jpg'
import ImgImpactCare from './impactcare.jpg'
import { Box, Typography } from '@mui/material'

const BannerIntake = () => {
  return (
    <Box sx={{marginBottom: '100px'}} className="banner-complete">
      <Box sx={{
        width: '100%',
        height: '585px',
        backgroundImage: `url(${ImgImpactful})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
      </Box>
    </Box>
  )
}

export default BannerIntake