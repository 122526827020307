export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const maritalStatusesOptions = [
  { label: "Married", value: "Married" },
  { label: "Separated", value: "Separated" },
  { label: "Not Married", value: "Not Married" },
  { label: "Widowed", value: "Widowed" },
  { label: "Divorced", value: "Divorced" },
  { label: "Not Applicable", value: "Not Applicable" },
];

export const childExperiencedProblemsOptions = [
  ["Fighting", "Detention", "Poor attendance", "Incomplete homework"],
  ["Lack of friends", "Suspension", "Poor grades", "Drug/Alcohol"],
  ["Learning Disabilities", "Gang influence", "Behaviour problems"],
];

export const relationshipOptions = [
  { label: "Mother", value: "Mother" },
  { label: "Father", value: "Father" },
  { label: "Guardian", value: "Guardian" },
  { label: "Younger Sibling", value: "Younger Sibling" },
  { label: "Elder Sibling", value: "Elder Sibling" },
  { label: "Grandparent", value: "Grandparent" },
  { label: "Relative", value: "Relative" },
  { label: "Helper", value: "Helper" },
];

export const getListAgeOptions = () => {
  let result = [];
  for (let i = 1; i <= 100; i++) {
    result.push({ label: i, value: i });
  }
  return result;
};
