import { Typography } from "@mui/material";
import React from "react";

const FieldError = ({ message }) => {
  return (
    <Typography
      color={"error"}
      variant="subtitle1"
      sx={{ fontSize: "12px", marginTop: 1 }}
    >
      {message}
    </Typography>
  );
};

export default FieldError;
