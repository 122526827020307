import React, { useEffect, useState } from "react";
import imgImpactFul from "./banner/impactful.jpg";
import imgImpactCare from "./banner/impactcare.jpg";
import "./index.css";

const BannerEnquiry = ({ pillar }) => {
  const [content, setContent] = useState({
    heading: "CampusImpact",
    body: "Helping young people from low-income and disadvantaged families acquire skills and values to become useful citizens for the community and serve as pillars of strength for the nation.",
    img: imgImpactFul,
  });
  const listContent = {
    IMPACTFUL: {
      heading: "CampusImpact",
      body: "Helping young people from low-income and disadvantaged families acquire skills and values to become useful citizens for the community and serve as pillars of strength for the nation.",
      img: imgImpactFul,
    },
    IMPACTCARE: {
      heading: "Impact Care",
      body: "Helping young people from low-income and disadvantaged families acquire skills and values to become useful citizens for the community and serve as pillars of strength for the nation.",
      img: imgImpactCare,
    },
    IMPACTFAMILY: {
      heading: "Impact Family",
      body: "Helping young people from low-income and disadvantaged families acquire skills and values to become useful citizens for the community and serve as pillars of strength for the nation.",
      img: imgImpactFul,
    },
    IMPACTREACH: {
      heading: "Impact Reach",
      body: "Helping young people from low-income and disadvantaged families acquire skills and values to become useful citizens for the community and serve as pillars of strength for the nation.",
      img: imgImpactCare,
    },
  };

  useEffect(() => {
    setContent(listContent[pillar]);
  }, [pillar]);

  return (
    <div className="banner-enquiry">
      <div className="banner-enquiry-content">
        <h4>{content.heading}</h4>
        <p>{content.body}</p>
      </div>
      <div className="banner-enquiry-image">
        <img
          src={content.img}
          alt="impact ful"
          style={{ width: "100%", transition: "1000" }}
        />
      </div>
    </div>
  );
};

export default BannerEnquiry;
