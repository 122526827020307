export const getGeneralPayloads = (data) => {
  let payload = {
    enquiryId: data.enquiryId,
    academicId: data.academicId,
    serviceTypes: data.serviceTypes,
    isDraft: data.isDraft,
    isSubsidy: data.isSubsidy,
    isDeclare: data.isDeclare,
    isCollecting: data.isCollecting,
    isUsing: data.isUsing,
    isPublish: data.isPublish,
  };
  if (data.serviceTypes.includes("Study Buddy")) {
    payload["step"] = data.step + 1;
  }
  return payload;
};

export const getLearningCurvePayloads = (data) => {
  return {
    subjects: data.subjects,
    fullName: data.fullName,
    chineseName: data.chineseName,
    postalCode: data.postalCode,
    unitNumber: data.unitNumber,
    address: data.address,
    gender: data.gender,
    phone: data.phone,
    dob: data.dob,
    contact: data.contact,
    race: data.race,
    otherRace: data.otherRace,
    email: data.email,
    nationality: data.nationality,
    otherNationality: data.otherNationality,
    nric: data.nric,
    educationLevel: data.educationLevel,
    stream: data.stream,
    schoolName: data.schoolName,
    recentExamResults: data.recentExamResults,

    parentSalutation: data.parentSalutation,
    parentFullName: data.parentFullName,
    parentContact: data.parentContact,
    parentNRIC: data.parentNRIC,
    parentEmail: data.parentEmail,
    relationshipToStudent: data.relationshipToStudent,
    parentNationality: data.parentNationality,
    otherParentNationality: data.otherParentNationality,
    mainConcern: data.mainConcern,

    spouseName: data.spouseName,
    noFamilyMembers: data.noFamilyMembers,
    accommodationType: data.accommodationType,
    houseHoldIncome: data.houseHoldIncome,
    incomeAbove: data.incomeAbove,
    cpfContribution: data.cpfContribution,
    payslip: data.payslip,
  };
};

export const getStudyBuddyPaylods = (data) => {
  switch (data.step + 1) {
    case 1:
      return {
        isDraft: true,
        session: data.session,
        holidayTiming: data.holidayTiming,
        time: data.time,

        // ---- student information ------
        fullName: data.fullName,
        nric: data.nric,
        gender: data.gender,
        schoolName: data.schoolName,
        dob: data.dob,
        educationLevel: data.educationLevel,
        race: data.race,
        otherRace: data.otherRace,
        citizenship: data.citizenship,
        otherCitizenship: data.otherCitizenship,
        mainConcern: data.mainConcern,

        // ------ home address -----
        postalCode: data.postalCode,
        address: data.address,
        contact: data.contact,
        unitNumber: data.unitNumber,

        attendance: data.attendance,
        punctuality: data.punctuality,
      };
    case 2:
      return {
        isDraft: true,
        // ------ late or unable -------
        dayLate: data?.dayLate,
        reasonComing: data?.reasonComing,
        dayNotAttending: data?.dayNotAttending,
        reasonNotAttending: data?.reasonNotAttending,

        // ------ parent information ------
        fatherName: data?.fatherName,
        fatherNric: data?.fatherNric,
        fatherContactNo: data?.fatherContactNo,
        fatherEmail: data?.fatherEmail,
        fatherOccupation: data?.fatherOccupation,
        motherName: data?.motherName,
        motherNric: data?.motherNric,
        motherContactNo: data?.motherContactNo,
        motherEmail: data?.motherEmail,
        motherOccupation: data?.motherOccupation,
        maritalStatuses: data?.maritalStatuses,
        mainCaregiver: data?.mainCaregiver,

        // ------- guardian information ------
        guardianName: data?.guardianName,
        guardianContactNo: data?.guardianContactNo,
        guardianNric: data?.guardianNric,
        guardianEmail: data?.guardianEmail,
      };
    default:
      return {
        isDraft: false,
        // ------- household information --------
        familyMembers: data.familyMembers,
        noFamilyMembers: data.noFamilyMembers,
        whoIsStaying: data.whoIsStaying,
        houseHoldIncome: data.houseHoldIncome,
        incomeAbove: data.incomeAbove,
        accommodationType: data.accommodationType,

        // ------- others information -------
        allergies: data.allergies,
        sourceOfReferral: data.sourceOfReferral,
        provideSourceOfReferral: data.provideSourceOfReferral,

        payslipSB: data.payslipSB,
      };
  }
};

export const managePayload = (payload) => {
  const fd = new FormData();
  let fileKeys = ["payslip", "payslipSB", "cpfContribution"];
  for (const [key, value] of Object.entries(payload)) {
    if (value !== null && value !== undefined) {
      fd.append(
        key,
        typeof value === "object" && !fileKeys.includes(key)
          ? JSON.stringify(value)
          : value
      );
    }
  }
  return fd;
};
