import moment from "moment";

// ------ helper function -------
const valOrEmpty = (value) => (value ? value : "");
const valOrFalse = (value) => (value ? value : false);

export const mappingValue = (
  methods,
  enquiry,
  intake,
  householdMembers,
  significantOthers
) => {
  // ------ mapping value  ---------
  const { setValue } = methods;
  setValue("intakeId", valOrEmpty(intake?.Id));
  setValue("enquiryId", valOrEmpty(enquiry?.Id));
  if (/^self[\w-]+/i.test(enquiry.Source_of_Referral__c))
    setValue("formInfo", "Self");

  // ================== FORM BOTH ==================
  // * step 1
  setValue("fullName", valOrEmpty(enquiry?.Client_s_Full_Name__c));
  setValue(
    "dob",
    valOrEmpty(moment(enquiry?.Client_s_DOB__c).format("DD-MM-YYYY"))
  );
  setValue("gender", valOrEmpty(enquiry?.Client_s_Gender__c?.toLowerCase()));
  setValue("todayDate", valOrEmpty(moment().format("DD-MM-YYYY")));

  // * step 2
  setValue("mother", valOrEmpty(enquiry?.Mother_s_Name__c));
  setValue("father", valOrEmpty(enquiry?.Father_s_Name__c));
  setValue("maritalStatus", valOrEmpty(enquiry?.Parent_s_Marital_Status__c));
  if (householdMembers?.length) {
    const tmpItem = householdMembers.map((item, i) => {
      return {
        key: i,
        Id: item.Id,
        name: item.Name__c,
        age: item.Age__c,
        relationship: item.Relationship_to_Client__c,
      };
    });
    setValue("householdMembers", tmpItem);
  }
  if (significantOthers?.length) {
    const tmpItem = significantOthers.map((item, i) => {
      return {
        key: i,
        Id: item.Id,
        name: item.Name__c,
        age: item.Age__c,
        relationship: item.Relationship_to_Client__c,
      };
    });

    setValue("childsSignificantOthers", tmpItem);
  }

  // * step 3
  setValue(
    "childEverExperiencedAbuse",
    valOrEmpty(intake?.Child_Ever_Experienced_Abuse__c)
  );
  setValue(
    "childEverMadeStatementsOfWantingToHurt",
    valOrEmpty(intake?.Child_Ever_Statement_Wanting_To_Hurt__c)
  );
  setValue(
    "childEverPurposelyHurtHimself",
    valOrEmpty(intake?.Child_Ever_Purposely_Hurt_Himself__c)
  );
  setValue(
    "childEverExperiencedAnySeriousEmitionalLosses",
    valOrEmpty(intake?.Child_Ever_Has_Serious_Emotional_Losses__c)
  );
  setValue(
    "thingsThatAreCurrentlyStressful",
    valOrEmpty(intake?.Child_Ever_Currently_Stressful__c)
  );

  // =============== FORM CHILD ===============
  // * step 1
  if (intake?.Behavioural_Excesses__c) setValue("formInfo", "Parent and Child");
  setValue("behaviouralExcesses", valOrEmpty(intake?.Behavioural_Excesses__c));
  setValue("behaviouralDeficits", valOrEmpty(intake?.Behavioural_Deficits__c));
  setValue("behaviouralAssets", valOrEmpty(intake?.Behavioural_Assets__c));
  setValue("otherConcerns", valOrEmpty(intake?.Other_Concerns__c));
  setValue("treatmentGoals", valOrEmpty(intake?.Treatment_Goals__c));

  // * step 2
  setValue(
    "describePastCounselling",
    valOrEmpty(intake?.Describe_Past_Counselling__c)
  );

  // * step 3
  setValue("schoolName", valOrEmpty(intake?.School_Name__c));
  setValue("formTeacherName", valOrEmpty(intake?.Teacher_s_Name__c));
  setValue("currentClass", valOrEmpty(intake?.Current_Class__c));
  setValue(
    "childsTeacherSayAbout",
    valOrEmpty(intake?.Child_s_Teacher_Say_About__c)
  );
  setValue(
    "childEverRepeatedAClass",
    valOrEmpty(intake?.Child_Ever_Repeated_Class__c)
  );
  setValue(
    "childEverReceivedEducationServices",
    valOrEmpty(intake?.Child_Ever_Received_Education_Services__c)
  );
  if (intake?.Child_Experienced_Problems__c) {
    const listProblem = intake?.Child_Experienced_Problems__c.split(";");
    setValue("childExperiencedProblems", listProblem);
  }

  // =============== FORM SELF ===============
  if (intake?.Suggested_To_Seek_Counselling__c) setValue("formInfo", "Self");
  setValue(
    "suggestedToSeekCounselling",
    valOrEmpty(intake?.Suggested_To_Seek_Counselling__c)
  );
  setValue(
    "reasonsForSeekingCounselling",
    valOrEmpty(intake?.Reason_For_Seeking_Counselling__c)
  );
  setValue("gainFromBeingInCounselling", valOrEmpty(intake?.Hope_To_Gain__c));
  setValue("yourStrengths", valOrEmpty(intake?.Strengths__c));
  setValue("everCounseled", valOrEmpty(intake?.Ever_Counseled__c));
  setValue("helpingProfessional", valOrEmpty(intake?.Helping_Professional__c));
  setValue("wasItAGoodExperience", valOrEmpty(intake?.Good_Experience__c));
  setValue("listAnyMedications", valOrEmpty(intake?.List_Any_Medications__c));
  setValue("toTheHospitalForMentalHealth", valOrEmpty(""));
  setValue("toTheHospitalForSubstanceUse", valOrEmpty(""));
};
