import React from "react";
import { Box } from "@mui/material";

const Section = (props) => {
  return (
    <Box {...props} sx={{ marginBottom: "45px" }}>
      {props.children}
    </Box>
  );
};

export default Section;
