import React, { useEffect } from "react";

export const useSyncField = (methods, currentForm) => {
  const { watch, setValue } = methods;
  useEffect(() => {
    setValue("sbFullName", watch("fullName"));
  }, [watch("fullName")]);
  useEffect(() => {
    setValue("fullName", watch("sbFullName"));
  }, [watch("sbFullName")]);

  useEffect(() => {
    setValue("sbNric", watch("nric"));
  }, [watch("nric")]);
  useEffect(() => {
    setValue("nric", watch("sbNric"));
  }, [watch("sbNric")]);

  useEffect(() => {
    setValue("sbGender", watch("gender"));
  }, [watch("gender")]);
  useEffect(() => {
    setValue("gender", watch("sbGender"));
  }, [watch("sbGender")]);

  useEffect(() => {
    setValue("sbSchoolName", watch("schoolName"));
  }, [watch("schoolName")]);
  useEffect(() => {
    setValue("schoolName", watch("sbSchoolName"));
  }, [watch("sbSchoolName")]);

  useEffect(() => {
    setValue("sbDob", watch("dob"));
  }, [watch("dob")]);
  useEffect(() => {
    setValue("dob", watch("sbDob"));
  }, [watch("sbDob")]);

  useEffect(() => {
    setValue("sbEducationLevel", watch("educationLevel"));
  }, [watch("educationLevel")]);
  useEffect(() => {
    setValue("educationLevel", watch("sbEducationLevel"));
  }, [watch("sbEducationLevel")]);

  useEffect(() => {
    setValue("sbRace", watch("race"));
  }, [watch("race")]);
  useEffect(() => {
    setValue("race", watch("sbRace"));
  }, [watch("sbRace")]);

  useEffect(() => {
    setValue("sbOtherRace", watch("otherRace"));
  }, [watch("otherRace")]);
  useEffect(() => {
    setValue("otherRace", watch("sbOtherRace"));
  }, [watch("sbOtherRace")]);

  useEffect(() => {
    setValue("sbMainConcern", watch("mainConcern"));
  }, [watch("mainConcern")]);
  useEffect(() => {
    setValue("mainConcern", watch("sbMainConcern"));
  }, [watch("sbMainConcern")]);

  useEffect(() => {
    setValue("sbPostalCode", watch("postalCode"));
  }, [watch("postalCode")]);
  useEffect(() => {
    setValue("postalCode", watch("sbPostalCode"));
  }, [watch("sbPostalCode")]);

  useEffect(() => {
    setValue("sbUnitNumber", watch("unitNumber"));
  }, [watch("unitNumber")]);
  useEffect(() => {
    setValue("unitNumber", watch("sbUnitNumber"));
  }, [watch("sbUnitNumber")]);

  useEffect(() => {
    setValue("sbAddress", watch("address"));
  }, [watch("address")]);
  useEffect(() => {
    setValue("address", watch("sbAddress"));
  }, [watch("sbAddress")]);

  useEffect(() => {
    setValue("sbContact", watch("contact"));
  }, [watch("contact")]);
  useEffect(() => {
    setValue("contact", watch("sbContact"));
  }, [watch("sbContact")]);

  useEffect(() => {
    setValue("sbNoFamilyMembers", watch("noFamilyMembers"));
  }, [watch("noFamilyMembers")]);
  useEffect(() => {
    setValue("noFamilyMembers", watch("sbNoFamilyMembers"));
  }, [watch("sbNoFamilyMembers")]);

  useEffect(() => {
    setValue("sbHouseholdIncome", watch("houseHoldIncome"));
  }, [watch("houseHoldIncome")]);
  useEffect(() => {
    setValue("houseHoldIncome", watch("sbHouseholdIncome"));
  }, [watch("sbHouseholdIncome")]);

  useEffect(() => {
    setValue("sbIncomeAbove", watch("incomeAbove"));
  }, [watch("incomeAbove")]);
  useEffect(() => {
    setValue("incomeAbove", watch("sbIncomeAbove"));
  }, [watch("sbIncomeAbove")]);

  useEffect(() => {
    setValue("sbAccommodationType", watch("accommodationType"));
  }, [watch("accommodationType")]);
  useEffect(() => {
    setValue("accommodationType", watch("sbAccommodationType"));
  }, [watch("sbAccommodationType")]);

  useEffect(() => {
    setValue("sbAccommodationType", watch("accommodationType"));
  }, [watch("accommodationType")]);
  useEffect(() => {
    setValue("accommodationType", watch("sbAccommodationType"));
  }, [watch("sbAccommodationType")]);

  useEffect(() => {
    setValue("sbIsSubsidy", watch("isSubsidy"));
  }, [watch("isSubsidy")]);
  useEffect(() => {
    setValue("isSubsidy", watch("sbIsSubsidy"));
  }, [watch("sbIsSubsidy")]);

  // -------------- handle change parent information lc form -----------------
  useEffect(() => {
    if (/learning curve/i.test(currentForm)) {
      const name = watch("parentFullName");
      const nric = watch("parentNRIC");
      const contact = watch("parentContact");
      const email = watch("parentEmail");
      const spouseName = watch("spouseName");
      setValue("fatherName", "");
      setValue("fatherNric", "");
      setValue("fatherContactNo", "");
      setValue("fatherEmail", "");
      setValue("motherName", "");
      setValue("motherName", "");
      setValue("motherNric", "");
      setValue("motherContactNo", "");
      setValue("motherEmail", "");
      setValue("fatherName", "");
      setValue("guardianName", "");
      setValue("guardianNric", "");
      setValue("guardianContactNo", "");
      setValue("guardianEmail", "");

      if (/father/i.test(watch("relationshipToStudent"))) {
        setValue("fatherName", name);
        setValue("fatherNric", nric);
        setValue("fatherContactNo", contact);
        setValue("fatherEmail", email);
        setValue("motherName", spouseName);
      } else if (/mother/i.test(watch("relationshipToStudent"))) {
        setValue("motherName", name);
        setValue("motherNric", nric);
        setValue("motherContactNo", contact);
        setValue("motherEmail", email);
        setValue("fatherName", spouseName);
      } else if (/guardian/i.test(watch("relationshipToStudent"))) {
        setValue("guardianName", name);
        setValue("guardianNric", nric);
        setValue("guardianContactNo", contact);
        setValue("guardianEmail", email);
      }
    }
  }, [
    watch("parentFullName"),
    watch("parentNRIC"),
    watch("parentContact"),
    watch("parentEmail"),
    watch("spouseName"),
    watch("relationshipToStudent"),
  ]);

  // ------- handle change main caregiver sb form --------------
  useEffect(() => {
    if (/father/i.test(watch("mainCaregiver"))) {
      setValue("parentFullName", watch("fatherName"));
      setValue("parentNRIC", watch("fatherNric"));
      setValue("parentContact", watch("fatherContactNo"));
      setValue("parentEmail", watch("fatherEmail"));
      setValue("relationshipToStudent", "Father");
      setValue("spouseName", watch("motherName"));
    } else if (/mother/i.test(watch("mainCaregiver"))) {
      setValue("parentFullName", watch("motherName"));
      setValue("parentNRIC", watch("motherNric"));
      setValue("parentContact", watch("motherContactNo"));
      setValue("parentEmail", watch("motherEmail"));
      setValue("relationshipToStudent", "Mother");
      setValue("spouseName", watch("fatherName"));
    } else if (/guardian/i.test(watch("mainCaregiver"))) {
      setValue("parentFullName", watch("guardianName"));
      setValue("parentNRIC", watch("guardianNric"));
      setValue("parentContact", watch("guardianContactNo"));
      setValue("parentEmail", watch("guardianEmail"));
      setValue("relationshipToStudent", "Guardian");
      setValue("spouseName", "");
    }
  }, [watch("mainCaregiver")]);
};
