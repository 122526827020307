import React from "react";
import { Header } from "../../Components/Header/index";
import { ImageTitle } from "../../Components/ImageTitle/index";
import { Footer } from "../../Components/Footer/index";
import { Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";

export const ThankYou = () => {
  return (
    <>
      <Helmet>
        <title>Thank You - Campus Impact</title>
      </Helmet>
      <Header />
      <ImageTitle />
      <Box sx={{ marginTop: 10, marginBottom: 10 }}>
        <Typography textAlign={"center"} variant="h4">
          Thank you for filling up the form. Our staff will contact you soon.
        </Typography>
      </Box>
      <Footer />
    </>
  );
};
