import React from "react";
import { Switch, Route } from "react-router-dom";
import FormImpactful from "./FormImpactful";
import FormImpactcare from "./FormImpactcare";
import { Helmet } from "react-helmet";

const EnquiryCompleteForm = () => {
  return (
    <>
      <Helmet>
        <title>Enquiry Complete Form - Campus Impact</title>
      </Helmet>
      <Switch>
        <Route
          path={`/enquiry-complete-form/impactful`}
          component={FormImpactful}
        />
        <Route
          path={`/enquiry-complete-form/impactcare`}
          component={FormImpactcare}
        />
      </Switch>
    </>
  );
};

export default EnquiryCompleteForm;
