import React from "react";
import ImgImpactful from "./impactful.jpg";
import ImgImpactCare from "./impactcare.jpg";
import { Box, Typography } from "@mui/material";
import "./index.css";

const BannerEnquiryComplete = ({ pillar = "Impactful", services = [] }) => {
  return (
    <Box sx={{ marginBottom: "215px" }} className="banner-complete">
      <Box
        className="background"
        sx={{
          backgroundImage: `url(${ImgImpactful})`,
        }}
      ></Box>
      <div className="pillar-item">
        <h4>{pillar}</h4>
        <ul>
          {services.map((service, i) => (
            <li key={i}>{service}</li>
          ))}
        </ul>
      </div>
    </Box>
  );
};

export default BannerEnquiryComplete;
