import * as Yup from "yup";
import {
  lcFields,
  LCFieldValidation,
  validateContact,
  validateNRIC,
  validateRecentExamResults,
} from "./validationHelper";
import { fnUniqueNRIC } from "./fnUniqueNRIC";

export const LCSchema = {
  subjects: Yup.array().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.array().test("subjects", "This field is required", (value) => {
      return value.length;
    }),
  }),
  chineseName: Yup.string().nullable(),
  phone: Yup.string()
    .test("phone", "Please provide a valid contact number", (value) =>
      !value.length ? true : /^na$/i.test(value) ? true : validateContact(value)
    )
    .when(lcFields, {
      is: LCFieldValidation,
      then: Yup.string().required("This field is required"),
    }),
  email: Yup.string()
    .email("Please provide a valid email")
    .when(lcFields, {
      is: LCFieldValidation,
      then: Yup.string().required("This field is required"),
    }),
  nationality: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  otherNationality: Yup.string().when(["nationality"], {
    is: (val) => /others/i.test(val),
    then: Yup.string().required("This field is required"),
  }),
  stream: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().when("educationLevel", {
      is: (level) =>
        [
          "Secondary 3 Express",
          "Secondary 4/5 O Level",
          "Primary 1",
          "Primary 2",
          "Primary 3",
        ].includes(level),
      then: Yup.string(),
      otherwise: Yup.string().required("This field is required"),
    }),
  }),
  recentExamResults: Yup.array().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.array().test(
      "recentExamResults",
      "Please complete all of the field",
      validateRecentExamResults
    ),
  }),

  parentSalutation: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  parentFullName: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  parentContact: Yup.string()
    .test("parentContact", "Please provide a valid contact number", (value) =>
      value?.length > 0 ? validateContact(value) : true
    )
    .when(lcFields, {
      is: LCFieldValidation,
      then: Yup.string().required("This field is required"),
    }),
  parentNRIC: Yup.string()
    .test("parentNRIC", null, (str, ctx) =>
      validateNRIC(str, ctx, "parentNRIC")
    )
    .test("parentNRIC", "NRIC must be unique", fnUniqueNRIC)
    .when(lcFields, {
      is: LCFieldValidation,
      then: Yup.string().required("This field is required"),
    }),
  parentEmail: Yup.string()
    .email("Please provide a valid email")
    .when(lcFields, {
      is: LCFieldValidation,
      then: Yup.string()
        .email("Please provide a valid email")
        .required("This field is required"),
    }),
  relationshipToStudent: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  parentNationality: Yup.string().when(lcFields, {
    is: LCFieldValidation,
    then: Yup.string().required("This field is required"),
  }),

  spouseName: Yup.string(),
  cpfContribution: Yup.mixed().test(
    "cpfContribution",
    "File Size is too large",
    (value) => {
      if (value) {
        return value.size <= 10000000;
      }
      return true;
    }
  ),
  payslip: Yup.mixed().test("payslip", "File Size is too large", (value) => {
    if (value) {
      return value.size <= 10000000;
    }
    return true;
  }),
};
