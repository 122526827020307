import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";

const SectionFormInformation = () => {
  const { control } = useFormContext;

  return (
    <Section>
      <SectionTitle title="Form Information" />
      <SectionBody>
        <Controller
          control={control}
          name="formInfo"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              value={value}
              row
              onChange={(e) => onChange(e.target.value)}
            >
              <FormControlLabel
                label="Parent and Child Intake Form"
                value="Parent and Child"
                control={<Radio />}
              />
              <FormControlLabel
                label="Self Intake Form"
                value="Self"
                control={<Radio />}
              />
            </RadioGroup>
          )}
        />
      </SectionBody>
    </Section>
  );
};

export default SectionFormInformation;
