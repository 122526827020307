import { useState } from "react";

export const useStep = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStep = (action) => {
    switch (action) {
      case "next":
        setActiveStep((prevStep) => prevStep + 1);
        break;
      case "back":
        setActiveStep((prevStep) => prevStep - 1);
        break;
      default:
        setActiveStep(activeStep);
    }
  };

  return {
    activeStep,
    setActiveStep,
    handleStep,
  };
};
