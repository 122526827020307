import React from "react";
import { Box } from "@mui/material";

const SectionBody = ({ children, ...props }) => {
  return (
    <Box className="section-body" {...props}>
      {children}
    </Box>
  );
};

export default SectionBody;
