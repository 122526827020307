import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button, IconButton } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Col, Form, Row } from "react-bootstrap";
import ReactSelect from "../../../../Components/Select/ReactSelect";
import IconDelete from "../../../../assets/delete_icon.svg";
import { getListAgeOptions, relationshipOptions } from "../options";
import { useFormContext } from "react-hook-form";

const styles = {
  btnAdd: {
    color: "#fff",
    textTransform: "capitalize",
    boxShadow: "none",
    borderRadius: "6px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    height: "40px",
  },
};

const SectionHouseholdMembers = ({ form = "child" }) => {
  const { setValue, watch } = useFormContext();
  const [isUpdateMembers, setIsUpdateMembers] = useState(false);
  const [houseHoldMembers, setHouseHoldMembers] = useState([
    { key: 1 },
    { key: 2 },
  ]);

  useEffect(() => {
    if (!isUpdateMembers && watch("householdMembers").length) {
      setHouseHoldMembers(watch("householdMembers"));
      setIsUpdateMembers(true);
    }
  }, [watch("householdMembers")]);

  useEffect(() => {
    setValue("householdMembers", houseHoldMembers);
  }, [houseHoldMembers]);

  const addItem = () => {
    setHouseHoldMembers([
      ...houseHoldMembers,
      {
        key: houseHoldMembers[houseHoldMembers.length - 1].key + 1,
      },
    ]);
  };

  const removeItem = (data) => {
    setHouseHoldMembers(
      houseHoldMembers.filter((item) => item.key !== data.key)
    );
  };

  const changeValueItem = (data, name, value) => {
    let tmpData = houseHoldMembers?.map((item) => {
      if (item.key === data.key) {
        item[name] = value;
      }
      return item;
    });
    setHouseHoldMembers(tmpData);
  };

  const BtnDelete = ({ data }) => (
    <Stack
      direciton={"row"}
      sx={{ width: "5%", transform: "translateY(5px)" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {houseHoldMembers.length > 1 && (
        <IconButton
          aria-label="delete"
          style={{ color: "#f50057" }}
          onClick={removeItem.bind(null, data)}
        >
          <img src={IconDelete} alt="delete icon" style={{ height: "20px" }} />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <Box sx={{ marginBottom: "14px", marginTop: "10px" }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "600" }}>
          Who are the other household living members with you?
        </Typography>
        <Button
          startIcon={<AddRoundedIcon />}
          disableElevation
          sx={styles.btnAdd}
          variant="contained"
          onClick={addItem}
        >
          Add Member
        </Button>
      </Stack>
      {houseHoldMembers.map((data) => (
        <Stack
          key={data.key}
          direction="row"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Row style={{ width: "95%" }}>
            <Col md={4}>
              <Form.Group className="form-group">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) =>
                    changeValueItem(data, "name", e.target.value)
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="form-group">
                <Form.Label>Age</Form.Label>
                <ReactSelect
                  placeholder="Choose Age"
                  options={getListAgeOptions()}
                  onChange={(item) => changeValueItem(data, "age", item.value)}
                  value={getListAgeOptions().find(
                    (item) => item.value === data.age
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="form-group">
                <Form.Label>Relationship to child</Form.Label>
                <ReactSelect
                  placeholder="Choose Relationship"
                  options={relationshipOptions}
                  value={relationshipOptions.find(
                    (item) => item.value === data.relationship
                  )}
                  onChange={(item) =>
                    changeValueItem(data, "relationship", item.value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <BtnDelete data={data} />
        </Stack>
      ))}
    </Box>
  );
};

export default SectionHouseholdMembers;
