import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FieldError from "../FieldError";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import ModalError from "../../Modal/ModalError";
import CheckBoxIcon from "../../../assets/checkbox_icon.svg";
import CheckedIcon from "../../../assets/checked_icon.svg";
import LogoCampus from "../../../assets/campus_impact_logo.png";
import { submitIntakeImpactful } from "../../../datasources/enquiryRepo";
import ToastSuccess from "../../Toast/ToastSuccess";
import LoadingButton from "@mui/lab/LoadingButton";

const validationSchema = Yup.object().shape({
  caregiver: Yup.string().required("This field is required"),
  childName: Yup.string().required("This field is required"),
  behaviour: Yup.string().required("This field is required"),
  emotions: Yup.string().required("This field is required"),
  learningNeeds: Yup.string().required("This field is required"),
  family: Yup.string().required("This field is required"),
  others: Yup.string().required("This field is required"),
  additionalComments: Yup.string().required("This field is required"),
});

const FormIntakeImpactcare = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [modalError, setModalError] = useState({
    show: false,
    error: null,
    message: null,
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: "",
      serviceType: [],
      caregiver: "",
      childName: "",
      behaviour: "",
      emotions: "",
      learningNeeds: "",
      family: "",
      others: "",
      additionalComments: "",
    },
  });

  useEffect(() => {
    const state = history.location.state;
    const tmpServiceType = [];
    if (state?.enquiry?.is_Learning_Curve__c) {
      tmpServiceType.push("Learning Curve");
    }
    if (state?.enquiry?.is_Study_Buddy__c) {
      tmpServiceType.push("Study Buddy");
    }

    setValue("enquiryId", state?.enquiry?.Id);
    setValue("serviceType", tmpServiceType);
    setValue("caregiver", state?.parent?.LastName);
    setValue("childName", state?.child?.LastName);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const state = history.location.state;
      const payload = {
        ...data,
        caregiver: state?.parent?.Id,
        childName: state?.child?.Id,
      };
      await submitIntakeImpactful(payload);
      setToast(true);
      reset();
    } catch (error) {
      setModalError({
        show: true,
        error: error,
        message: error.response?.data.message
          ? error.response.data.message
          : error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ background: "#F9FAF9", marginTop: "-150px" }}>
          <Container maxWidth="sm" sx={{ background: "#fff", padding: "40px" }}>
            <Box sx={{ marginBottom: "40px" }}>
              <LazyLoadImage
                alt="Logo"
                src={LogoCampus}
                style={{ width: "120px" }}
              />
            </Box>
            <Stack direction="column" gap={2}>
              <Form.Group className="form-group">
                <Form.Label>Service Type</Form.Label>
                <Stack direction={"row"} spacing={1}>
                  <Controller
                    control={control}
                    name="serviceType"
                    render={({ field: { value } }) => (
                      <FormControlLabel
                        label="Learning Curve"
                        control={
                          <Checkbox
                            disabled
                            checked={value.includes("Learning Curve")}
                            icon={<img src={CheckBoxIcon} />}
                            checkedIcon={<img src={CheckedIcon} />}
                          />
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="serviceType"
                    render={({ field: { value } }) => (
                      <FormControlLabel
                        label="Study Buddy"
                        control={
                          <Checkbox
                            checked={value.includes("Study Buddy")}
                            disabled
                            icon={<img src={CheckBoxIcon} />}
                            checkedIcon={<img src={CheckedIcon} />}
                          />
                        }
                      />
                    )}
                  />
                </Stack>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Caregiver / Significant Adult</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Caregiver / Significant Adult"
                  disabled
                  {...register("caregiver")}
                />
                <FieldError message={errors?.caregiver?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Name of Child</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of Child"
                  disabled
                  {...register("childName")}
                />
                <FieldError message={errors?.childName?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label style={{ fontSize: "18px" }}>Behaviour</Form.Label>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", marginBottom: "8px" }}
                >
                  Any concerns at home, any feedback from school. Any incidents
                  of concerns that may or may not be reported.
                </Typography>
                <Form.Control
                  type="text"
                  as="textarea"
                  style={{ height: "131px" }}
                  placeholder="Type here..."
                  {...register("behaviour")}
                />
                <FieldError message={errors?.behaviour?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label style={{ fontSize: "18px" }}>Emotions</Form.Label>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", marginBottom: "8px" }}
                >
                  Any emotions reported by child in the past 6 months that you
                  intervened (ask for help, seek counselling, spoke to child or
                  professional), any emotions observed or reported that needed
                  concerns
                </Typography>
                <Form.Control
                  type="text"
                  placeholder="Emotions"
                  {...register("emotions")}
                />
                <FieldError message={errors?.emotions?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label style={{ fontSize: "18px" }}>
                  Learning Needs
                </Form.Label>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", marginBottom: "8px" }}
                >
                  Any specific learning needs diagnosed or non-diagnosed that
                  needed support? Any feedback from school? If diagnosed, when
                  was it, from who and where?
                </Typography>
                <Form.Control
                  type="text"
                  placeholder="Learning Needs"
                  {...register("learningNeeds")}
                />
                <FieldError message={errors?.learningNeeds?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label style={{ fontSize: "18px" }}>Family</Form.Label>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", marginBottom: "8px" }}
                >
                  How is the child's rapport with family at home? Who is the
                  main care giver? Who is at home when child returns home from
                  school?
                </Typography>
                <Form.Control
                  type="text"
                  placeholder="Family"
                  {...register("family")}
                />
                <FieldError message={errors?.family?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label style={{ fontSize: "18px" }}>Others</Form.Label>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", marginBottom: "8px" }}
                >
                  How is the child's rapport with family at home? Who is the
                  main care giver? Who is at home when child returns home from
                  school?
                </Typography>
                <Form.Control
                  type="text"
                  placeholder="Others"
                  {...register("others")}
                />
                <FieldError message={errors?.others?.message} />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Additional Comments</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Additional Comments"
                  {...register("additionalComments")}
                />
                <FieldError message={errors?.additionalComments?.message} />
              </Form.Group>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                loadingPosition="start"
                sx={{
                  color: "#fff",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "6px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  height: "41px",
                  "& .MuiLoadingButton-loadingIndicator": {
                    position: "relative",
                    marginRight: "30px",
                  },
                }}
              >
                SUBMIT
              </LoadingButton>
            </Stack>
          </Container>
        </Box>
      </form>

      <ModalError
        show={modalError.show}
        error={modalError.error}
        close={() => setModalError({ show: false, error: null, message: null })}
        message={modalError.message}
      />
      <ToastSuccess open={toast} close={() => setToast(false)} />
    </>
  );
};

export default FormIntakeImpactcare;
