import { uniqueNric } from "./validationHelper";

export const fnUniqueNRIC = (str, ctx) => {
  const params = [
    ctx?.parent?.fatherNric,
    ctx?.parent?.motherNric,
    ctx?.parent?.guardianNric,
  ];
  switch (ctx.path) {
    case "nric":
      return uniqueNric(
        str,
        ctx?.parent?.parentNRIC,
        ctx?.parent?.nric,
        ...params
      );
    case "sbNric":
      return uniqueNric(
        str,
        ctx?.parent?.parentNRIC,
        ctx?.parent?.sbNric,
        ...params
      );
    case "parentNRIC":
      return uniqueNric(str, ctx?.parent?.nric, ctx?.parent?.parentNRIC);
    case "fatherNric":
    case "motherNric":
    case "guardianNric":
      return uniqueNric(str, ctx?.parent?.nric, ...params);
    default:
      return uniqueNric(
        str,
        ctx?.parent?.nric,
        ctx?.parent?.parentNRIC,
        ...params
      );
  }
};
