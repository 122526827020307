import { getEnquiryData } from "../../../../datasources/enquiryRepo";
import moment from "moment";

// ------ helper function -------
const valOrEmpty = (value) => (value ? value : "");
const valOrFalse = (value) => (value ? value : false);

export const fetchLatestData = async (history, methods, setPillarBanner) => {
  if (!history.location.state || !history.location.state?.enquiryData) {
    return history.replace("/verification-form");
  }
  const { setValue } = methods;
  const enquiryId = history.location.state.enquiryData.Id;

  setValue("academicId", valOrEmpty(history.location.state?.academicData?.Id));
  try {
    let response = await getEnquiryData({ id: enquiryId });
    const { enquiry, recentExamResult } = response.data;

    const services = [];
    if (enquiry.is_Learning_Curve__c) services.push("Learning Curve");
    if (enquiry.is_Study_Buddy__c) services.push("Study Buddy");
    setPillarBanner(enquiry.RecordType.Name);

    setValue(
      "academicId",
      valOrEmpty(history.location.state?.academicData?.Id)
    );
    setValue("isDeclare", valOrFalse(enquiry?.Information_Is_True__c));
    setValue("isCollecting", valOrFalse(enquiry?.Program_Providing_Consent__c));
    setValue("isUsing", valOrFalse(enquiry?.Other_Info_Consent__c));
    setValue("isPublish", valOrFalse(enquiry?.Photo_Video_Consent__c));
    // setValue("serviceTypes", services); // ! bug referesh
    setValue("enquiryStatus", valOrEmpty(enquiry?.EnquiryStatus__c));
    setValue(
      "subjects",
      enquiry?.Subject__c
        ? enquiry?.Subject__c?.split(";").map((subject) => {
            if (/a_math/i.test(subject)) return "A Math A";
            if (/e_math/i.test(subject)) return "E Math E";
            if (/combined science/i.test(subject))
              return "Combined Science (Phy/Chem)";
            return subject;
          })
        : []
    );

    // Student Information
    setValue("fullName", valOrEmpty(enquiry?.Client_s_Full_Name__c));
    setValue("chineseName", valOrEmpty(enquiry?.Client_s_Chinese_Name__c));
    setValue("postalCode", valOrEmpty(enquiry?.Postal_Code__c));
    setValue("unitNumber", valOrEmpty(enquiry?.Unit_Number__c));
    setValue("address", valOrEmpty(enquiry?.Address__c));
    setValue("gender", valOrEmpty(enquiry?.Client_s_Gender__c));
    setValue("phone", valOrEmpty(enquiry?.Client_s_Mobile__c));
    if (enquiry?.Client_s_DOB__c) {
      setValue(
        "dob",
        valOrEmpty(moment(enquiry?.Client_s_DOB__c).format("DD-MM-YYYY"))
      );
    }
    setValue("contact", valOrEmpty(enquiry?.Client_s_Phone__c));
    setValue("race", valOrEmpty(enquiry?.Race__c));
    setValue("otherRace", valOrEmpty(enquiry?.Other_Race__c));
    setValue("email", valOrEmpty(enquiry?.Client_s_Email__c));
    setValue(
      "nationality",
      valOrEmpty(enquiry?.Client_s_Nationality__c)
        ? valOrEmpty(enquiry?.Client_s_Nationality__c)
        : "Singapore"
    );
    setValue("nric", valOrEmpty(enquiry?.Client_s_ID_No__c));
    setValue("educationLevel", valOrEmpty(enquiry?.Educational_Level__c));
    setValue("stream", valOrEmpty(enquiry?.Stream__c));
    setValue("schoolName", valOrEmpty(enquiry?.Client_s_School__c));

    if (recentExamResult && recentExamResult.length) {
      let tmpData = recentExamResult.map((item, i) => {
        return {
          key: i + 1,
          subject: item.Subject_Name__c,
          target: item.TargetMarks__c,
          score: item.Actual_Marks__c,
        };
      });
      setValue("recentExamResults", tmpData);
    }

    // Applicant Information
    setValue("relationshipToStudent", valOrEmpty(enquiry?.Main_Caregiver__c));
    let parentData = {};
    let spouseName = "";
    if (/father/i.test(enquiry?.Main_Caregiver__c)) {
      parentData = {
        parentSalutation: enquiry?.Salutation_Dad__c,
        parentFullName: enquiry?.Father_s_Name__c,
        parentContact: enquiry?.Father_s_Mobile__c,
        parentNRIC: enquiry?.Father_s_Id_No__c,
        parentEmail: enquiry?.Father_s_Email__c,
        parentNationality: enquiry?.Father_s_Nationality__c
          ? enquiry?.Father_s_Nationality__c
          : "Singapore",
      };
      spouseName = enquiry?.Mother_s_Name__c;
    } else if (/mother/i.test(enquiry?.Main_Caregiver__c)) {
      parentData = {
        parentSalutation: enquiry?.Salutation_Mother__c,
        parentFullName: enquiry?.Mother_s_Name__c,
        parentContact: enquiry?.Mother_s_Mobile__c,
        parentNRIC: enquiry?.Mother_s_Id_No__c,
        parentEmail: enquiry?.Mother_s_Email__c,
        parentNationality: enquiry?.Mother_s_Nationality__c
          ? enquiry?.Mother_s_Nationality__c
          : "Singapore",
      };
      spouseName = enquiry?.Father_s_Name__c;
    } else if (/guardian/i.test(enquiry?.Main_Caregiver__c)) {
      parentData = {
        parentSalutation: enquiry?.Salutation_Guardian__c,
        parentFullName: enquiry?.Guardian_s_Name__c,
        parentContact: enquiry?.Guardian_s_Mobile__c,
        parentNRIC: enquiry?.Guardian_s_Id_No__c,
        parentEmail: enquiry?.Guardian_s_Email__c,
        parentNationality: enquiry?.Guardian_s_Nationality__c
          ? enquiry?.Guardian_s_Nationality__c
          : "Singapore",
      };
    }
    for (const [key, value] of Object.entries(parentData)) {
      setValue(key, valOrEmpty(value));
    }
    if (enquiry?.Concerns__c) {
      let concerns = enquiry.Concerns__c.split(";");
      setValue("mainConcern", concerns);
    }

    // Subsidy Information
    setValue("isSubsidy", valOrFalse(enquiry?.Subsidy_Required__c));
    setValue("spouseName", valOrEmpty(spouseName));
    setValue(
      "noFamilyMembers",
      valOrEmpty(enquiry?.No_of_Family_Members__c?.toString())
    );
    setValue("accommodationType", valOrEmpty(enquiry?.Accommodation_Type__c));
    setValue("houseHoldIncome", valOrEmpty(enquiry?.Family_Gross_Salary__c));
    setValue("incomeAbove", valOrEmpty(enquiry?.Family_Gross_Salary_Stated__c));

    setValue("session", valOrEmpty(enquiry?.Session_Term__c));

    // ------- Study buddy form -----
    setValue("session", valOrEmpty(enquiry?.Session_Term__c));
    if (enquiry?.Holiday_Timing__c) {
      let time = enquiry?.Holiday_Timing__c;
      if (/entire/i.test(time)) {
        setValue("holidayTiming", valOrEmpty("Entire Day"));
        setValue("time", valOrEmpty(""));
      } else {
        setValue("holidayTiming", valOrEmpty("Half Day"));
        setValue("time", valOrEmpty(time));
      }
    }
    setValue("sbFullName", valOrEmpty(enquiry?.Client_s_Full_Name__c));
    setValue("sbNric", valOrEmpty(enquiry?.Client_s_ID_No__c));
    setValue("sbGender", valOrEmpty(enquiry?.Client_s_Gender__c));
    setValue("sbSchoolName", valOrEmpty(enquiry?.Client_s_School__c));
    if (enquiry?.Client_s_DOB__c) {
      setValue(
        "sbDob",
        valOrEmpty(moment(enquiry?.Client_s_DOB__c).format("DD-MM-YYYY"))
      );
    }
    setValue("sbEducationLevel", valOrEmpty(enquiry?.Educational_Level__c));
    setValue("sbRace", valOrEmpty(enquiry?.Race__c));
    setValue("sbOtherRace", valOrEmpty(enquiry?.Other_Race__c));
    if (enquiry?.Concerns__c) {
      let concerns = enquiry.Concerns__c.split(";");
      setValue("sbMainConcern", concerns);
    }
    setValue("citizenship", valOrEmpty(enquiry?.Citizenship__c));
    setValue("sbPostalCode", valOrEmpty(enquiry?.Postal_Code__c));
    setValue("sbUnitNumber", valOrEmpty(enquiry?.Unit_Number__c));
    setValue("sbAddress", valOrEmpty(enquiry?.Address__c));
    setValue("sbContact", valOrEmpty(enquiry?.Client_s_Phone__c));
    setValue(
      "punctuality",
      valOrEmpty(enquiry?.Punctuality_Commitment__c === "Yes" ? "No" : "Yes")
    );
    if (enquiry?.Days_of_Late__c) {
      const days = enquiry?.Days_of_Late__c?.split(";");
      setValue("dayLate", days);
    }
    setValue("reasonComing", valOrEmpty(enquiry?.Reason_for_Late__c));
    if (enquiry?.Days_of_Non_Attendance__c) {
      const days = enquiry?.Days_of_Non_Attendance__c?.split(";");
      setValue("dayNotAttending", days);
    }
    setValue(
      "reasonNotAttending",
      valOrEmpty(enquiry?.Reason_for_Non_Attendance__c)
    );
    setValue("fatherName", valOrEmpty(enquiry?.Father_s_Name__c));
    setValue("fatherNric", valOrEmpty(enquiry?.Father_s_Id_No__c));
    setValue("fatherContactNo", valOrEmpty(enquiry?.Father_s_Mobile__c));
    setValue("fatherEmail", valOrEmpty(enquiry?.Father_s_Email__c));
    setValue("fatherOccupation", valOrEmpty(enquiry?.Father_Occupation__c));
    setValue("motherName", valOrEmpty(enquiry?.Mother_s_Name__c));
    setValue("motherNric", valOrEmpty(enquiry?.Mother_s_Id_No__c));
    setValue("motherContactNo", valOrEmpty(enquiry?.Mother_s_Mobile__c));
    setValue("motherEmail", valOrEmpty(enquiry?.Mother_s_Email__c));
    setValue("motherOccupation", valOrEmpty(enquiry?.Mother_Occupation__c));
    setValue(
      "maritalStatuses",
      valOrEmpty(enquiry?.Parent_s_Marital_Status__c)
    );
    setValue("mainCaregiver", valOrEmpty(enquiry?.Main_Caregiver__c));
    setValue("guardianName", valOrEmpty(enquiry?.Guardian_s_Name__c));
    setValue("guardianContactNo", valOrEmpty(enquiry?.Guardian_s_Mobile__c));
    setValue("guardianNric", valOrEmpty(enquiry?.Guardian_s_Id_No__c));
    setValue("guardianEmail", valOrEmpty(enquiry?.Guardian_s_Email__c));
    setValue("allergies", valOrEmpty(enquiry?.Allergies_Medications__c));
    setValue("sourceOfReferral", valOrEmpty(enquiry?.Source_of_Referral__c));
    setValue(
      "provideSourceOfReferral",
      valOrEmpty(enquiry?.Name_of_Source_of_Referral__c)
    );

    setValue(
      "sbNoFamilyMembers",
      valOrEmpty(enquiry?.No_of_Family_Members__c?.toString())
    );
    setValue("sbHouseholdIncome", valOrEmpty(enquiry?.Family_Gross_Salary__c));
    setValue(
      "sbIncomeAbove",
      valOrEmpty(enquiry?.Family_Gross_Salary_Stated__c)
    );
    setValue("sbAccommodationType", valOrEmpty(enquiry?.Accommodation_Type__c));
    setValue("sbIsSubsidy", valOrFalse(enquiry?.Subsidy_Required__c));
  } catch (error) {
    console.log(error);
  }
};
