import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const SectionIntakeSuccess = () => {
  return (
    <Section>
      <SectionBody sx={{ pb: 8 }}>
        <CheckCircleOutlineIcon
          sx={{ width: "48px", height: "48px", color: "green" }}
        />
        <Typography mt={2} mb={1} variant="h4">
          Submitted Successfully
        </Typography>
        <Typography mb={2}>Thank you for your submission!</Typography>
        <Link to="/enquiry-form">
          <Button variant="contained" sx={{ color: "white" }}>
            Back to Enquiry
          </Button>
        </Link>
      </SectionBody>
    </Section>
  );
};

export default SectionIntakeSuccess;
