import React from "react";
import { Snackbar, Alert } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const ToastSuccess = ({ open, close, text }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={2300}
      onClose={close}
    >
      <Alert
        icon={<CheckCircleRoundedIcon />}
        sx={{
          color: "#4A4C46",
          boxShadow: "0px 8px 24px rgba(37, 37, 37, 0.12)",
          width: "312px",
          marginTop: "150px",
        }}
        severity="success"
      >
        {text ? text : "Submit Successfull"}
      </Alert>
    </Snackbar>
  );
};

export default ToastSuccess;
