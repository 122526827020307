import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Typography, Stack, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const styleBox = {
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "6px",
  outline: "none",
};

const ModalSave = ({ open, onClose }) => {
  return (
    <Modal open={open}>
      <Box sx={styleBox}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          sx={{ padding: "20px", boxShadow: "inset 0px -1px 0px #F2F2F2" }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            Successful Save
          </Typography>
          <Box>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Stack>
        <Box sx={{ padding: "20px" }}>
          <Typography>Your data has been save!</Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ padding: "20px", boxShadow: "inset 0px 1px 0px #F2F2F2" }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={{ color: "#fff", textTransform: "capitalize" }}
            onClick={onClose}
          >
            Done
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalSave;
