import React , {useEffect} from 'react'
import NProgress from 'nprogress'

const LazyLoading = () => {
  useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    });

  return ''
}

export default LazyLoading