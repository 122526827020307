import React from 'react'
import { Box } from '@mui/material'
import Select from 'react-select'

const ReactSelect = (props) => (
  <Box sx={{'& input , &input:focus': { border: 'none !important' }}}>
    <Select {...props} styles={{
      option: (provided, state) => {
        return {
        ...provided,
        color: state.isSelected ? '#FD9344' : '#40423A',
        backgroundColor: state.isSelected || state.isFocused ? "#FFEEE1" : "#fff",
        cursor: state.isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...provided[':active'],
            backgroundColor: !state.isDisabled
              ? state.isSelected
                ? state.data.color
                : "#FFEEE1"
              : undefined,
          },
        fontWeight: state.isSelected ? "600" : "400" ,
        padding: '12px 20px',
        fontSize: '16px',
      }
      },
      input: (provided , state) => ({
        ...provided,
        transform: props.transform ? props.transform :  "translateY(-5px)"
      }),
      placeholder: (provided , state) => ({
        ...provided,
        transform: props.transform ? props.transform : "translateY(-5px)",
      }),
      control: (provided , state) => ({
        ...provided,
        border: '1px solid #B0B1AE',
        borderRadius: '6px',
        height: "42px",
        boxShadow: 'none',
        backgroundColor: state.isDisabled ? "#E9ECEF" : '#fff',
        "&:hover": {
          backgroundColor: '#FFEEE1',
        },
      }),
      menu: (provided , state) => ({
        ...provided,
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1);',
      }),
      indicatorsContainer: (provided , state) => ({
        ...provided,
        height: '100%',
      }),
      indicatorSeparator: (provided , state) => ({
        ...provided,
        display: 'none'
      }),
      singleValue: (provided , state) => ({
        ...provided,
        transform:props.transform ? props.transform : "translateY(-5px)"
      }),
      container: (provided , state) => {
        return {
          ...provided,
        }
      }
    }} 
      />
  </Box>
)

export default ReactSelect