import LoadingButton from "@mui/lab/LoadingButton";
import { Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";

const styles = {
  btnNext: {
    color: "#fff",
    width: "100%",
    boxShadow: "none",
    borderRadius: "6px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    height: "41px",
    "& .MuiLoadingButton-loadingIndicator": {
      position: "relative",
      marginRight: "30px",
    },
  },
  btnBack: {
    width: "100%",
    backgroundColor: "#FFEEE1",
    boxShadow: "none",
    borderRadius: "6px",
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "14px",
    height: "41px",
    "& .MuiLoadingButton-loadingIndicator": {
      position: "relative",
      marginRight: "30px",
    },
  },
};

const Navigation = ({
  loading,
  activeStep,
  listSteps,
  handleStep,
  openModalValidation,
}) => {
  const { watch } = useFormContext();

  return (
    <Section>
      <SectionBody>
        <Stack
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <LoadingButton
            loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={<></>}
            type="submit"
            onClick={(e) => {
              if (watch("step") === listSteps.length) {
                openModalValidation();
              }
            }}
            sx={styles.btnNext}
          >
            {activeStep === listSteps?.length - 1 ? "Submit" : "Next"}
          </LoadingButton>
          <LoadingButton
            type="button"
            loading={loading}
            loadingPosition="start"
            startIcon={<></>}
            onClick={() => handleStep("back")}
            disabled={activeStep === 0}
            sx={styles.btnBack}
          >
            BACK
          </LoadingButton>
        </Stack>
      </SectionBody>
    </Section>
  );
};

export default Navigation;
