export const defaultValues = {
  currentForm: "",
  enquiryId: "",
  enquiryStatus: "",
  academicId: "",
  serviceTypes: [],
  isDraft: false,
  step: 0,

  isDeclare: false,
  isCollecting: false,
  isUsing: false,
  isPublish: false,

  // ======= Form Learning Curve ==========
  subjects: [],
  // --- student information ---
  fullName: "",
  chineseName: "",
  postalCode: "",
  unitNumber: "",
  address: "",
  gender: "",
  phone: "",
  dob: "",
  contact: "",
  race: "",
  otherRace: "",
  email: "",
  nationality: "Singapore",
  nric: "",
  educationLevel: "",
  stream: "",
  schoolName: "",
  recentExamResults: [{ key: 1 }],
  // --- parent guardian information ---
  parentSalutation: "",
  parentFullName: "",
  parentContact: "",
  parentNRIC: "",
  parentEmail: "",
  relationshipToStudent: "",
  parentNationality: "Singapore",
  mainConcern: [],
  // --- subsidy information ---
  isSubsidy: false,
  spouseName: "",
  noFamilyMembers: "",
  accommodationType: "",
  houseHoldIncome: "",
  incomeAbove: "",
  cpfContribution: "",
  payslip: "",

  // ======= Form Study Buddy ==========
  session: "",
  holidayTiming: "",
  time: "",
  // --- studen information ---
  sbFullName: "",
  sbNric: "",
  sbGender: "",
  sbSchoolName: "",
  sbDob: "",
  sbEducationLevel: "",
  sbRace: "",
  sbOtherRace: "",
  sbMainConcern: "",
  citizenship: "",
  otherCitizenship: "",
  // --- home address ---
  sbPostalCode: "",
  sbUnitNumber: "",
  sbAddress: "",
  sbContact: "",

  attendance: "",
  punctuality: "",

  // --- late or unable ---
  dayLate: [],
  reasonComing: "",
  dayNotAttending: [],
  reasonNotAttending: "",

  // --- parent information ---
  fatherNotApplicable: false,
  fatherName: "",
  fatherNric: "",
  fatherContactNo: "",
  fatherEmail: "",
  fatherOccupation: "",
  motherNotApplicable: false,
  motherName: "",
  motherNric: "",
  motherContactNo: "",
  motherEmail: "",
  motherOccupation: "",
  maritalStatuses: "",
  mainCaregiver: "",

  // ---- guardian information ----
  guardianApplicable: false,
  guardianName: "",
  guardianContactNo: "",
  guardianNric: "",
  guardianEmail: "",

  // ---- household and other form ----
  familyMembers: [{ id: 1 }],
  sbNoFamilyMembers: "",
  whoIsStaying: [],
  sbHouseholdIncome: "",
  sbIncomeAbove: "",
  sbAccommodationType: "",
  allergies: "",
  sourceOfReferral: "",
  provideSourceOfReferral: "",
  sbIsSubsidy: false,

  payslipSB: "",
};
