import React from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";
import { maritalStatusesOptions } from "../options";
import SectionHouseholdMembers from "../components/SectionHouseholdMembers";
import SectionSignificantOthers from "../components/SectionSignificantOthers";
import { Controller, useFormContext } from "react-hook-form";
import FieldError from "../../../../Components/Forms/FieldError";

const StepFamilyProfile = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Section>
        <SectionTitle title="Family Profile" />
        <SectionBody>
          <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "600" }}>
            The name of the child's biological parents:
          </Typography>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Mother</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  {...register("mother")}
                />
                <FieldError message={errors?.mother?.message} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Father</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  {...register("father")}
                />
                <FieldError message={errors?.father?.message} />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="form-group">
            <Form.Label>
              Please select one of the marital statuses of the child's parents
              under this option.
            </Form.Label>
            <Controller
              control={control}
              name="maritalStatus"
              render={({ field: { onChange, value } }) => (
                <RadioGroup row onChange={(e) => onChange(e.target.value)}>
                  {maritalStatusesOptions.map((status) => (
                    <FormControlLabel
                      key={status.label}
                      label={status.label}
                      value={status.value}
                      checked={status.value === value}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            <FieldError message={errors?.maritalStatus?.message} />
          </Form.Group>

          <SectionHouseholdMembers form="child" />

          <SectionSignificantOthers form="child" />

          <Form.Group className="form-group">
            <Form.Label>
              Please describe any past counselling that either your child or any
              family member has attended.
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("describePastCounselling")}
            />
            <FieldError message={errors?.describePastCounselling?.message} />
          </Form.Group>
        </SectionBody>
      </Section>
    </>
  );
};

export default StepFamilyProfile;
