import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import StepperComponent from "../../../../Components/Stepper/Stepper";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import StepChildIntake from "./StepChildForm";
import StepFamilyProfile from "./StepFamilyProfile";
import StepEducationHistory from "./StepEducationHistory";
import { useStep } from "../helpers/useStep";
import Navigation from "../components/Navigation";
import { getChildPayloads, getGeneralPayloads } from "../payloads";
import { submitEnquiryCompleteFormImpactCare } from "../../../../datasources/enquiryRepo";
import ToastSuccess from "../../../../Components/Toast/ToastSuccess";
import { Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import SectionIntakeSuccess from "../components/SectionIntakeSuccess";

const listSteps = [
  "Child Intake Form",
  "Family Profile Form",
  "Education History Form",
];

const FormChild = ({ setModalError, openModalValidation }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { handleSubmit, setValue } = useFormContext();
  const { activeStep, handleStep } = useStep();
  const [toast, setToast] = useState({
    open: false,
    text: "Your data has been save!",
  });

  useEffect(() => {
    window.scrollTo(0, 590);
    if (!isSubmitted) setValue("step", activeStep + 1);
  }, [activeStep, isSubmitted]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const generalPayloads = getGeneralPayloads(data);
      const formChildPayloads = getChildPayloads(data);
      const allPayloads = { ...generalPayloads, ...formChildPayloads };
      await submitEnquiryCompleteFormImpactCare(allPayloads);
      if (activeStep < 2) {
        handleStep("next");
      } else {
        setIsSubmitted(true);
        setToast({ ...toast, open: true });
      }
    } catch (error) {
      setModalError({
        show: true,
        error: error,
        message: error.response?.data?.message
          ? error.response?.data?.message
          : error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  if (isSubmitted) return <SectionIntakeSuccess />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Section>
          <SectionBody>
            <StepperComponent
              activeStep={activeStep}
              listSteps={listSteps}
              firstLabelTransform={"translateX(60px)"}
              lastLabelTransform={"translateX(-80px)"}
            />
          </SectionBody>
        </Section>

        {(() => {
          switch (activeStep + 1) {
            case 1:
              return <StepChildIntake />;
            case 2:
              return <StepFamilyProfile />;
            case 3:
              return <StepEducationHistory />;
            default:
              <>Not Found</>;
          }
        })()}

        <Navigation
          loading={loading}
          activeStep={activeStep}
          listSteps={listSteps}
          handleStep={handleStep}
          openModalValidation={openModalValidation}
        />
      </form>

      <ToastSuccess
        open={toast.open}
        close={() => setToast({ ...toast, open: false })}
        text={toast.text}
      />
    </>
  );
};

export default FormChild;
