import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { TitleSignupDetail } from "../TitleSignupDetail/index";
import Select from "react-select";

import "./ApplicationFormStep.css";

export function ApplicationFormStep() {
  const SalutationOption = [
    { value: "Mr", label: "Mr." },
    { value: "Mrs", label: "Mrs." },
    { value: "Dr", label: "Dr." },
  ];
  return (
    <>
      <div className="container-form">
        <div className="container-form-signup-detail">
          <TitleSignupDetail title={"Personal Particulars"} />
        </div>
        <Row>
          <Col xl={4} xs={12}>
            <Form.Label>Salutation *</Form.Label>
            <Select classnameprefix="select" options={SalutationOption} />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Full Name as in NRIC *</Form.Label>
            <Form.Control type="email" placeholder="Full Name" />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Date of Birth *</Form.Label>
            <Form.Control type="date" />
          </Col>
        </Row>
        <Row className="form-row">
          <Col xl={4} xs={12}>
            <Form.Label>ID Type *</Form.Label>
            <Select
              classnameprefix="select"
              options={[
                {
                  value: "NRIC",
                  label: "NRIC",
                },
                {
                  value: "FIN",
                  label: "FIN",
                },
              ]}
            />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>ID Number*</Form.Label>
            <Form.Control type="text" placeholder="ID Number" />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Gender</Form.Label>
            <Select
              classnameprefix="select"
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
              ]}
            />
          </Col>
        </Row>
      </div>
      <div className="container-form">
        {" "}
        <div className="container-form-signup-detail">
          <TitleSignupDetail title={"Other Informations"} />
        </div>
        <Row>
          <Col xl={4} xs={12}>
            <Form.Label>Salutation *</Form.Label>
            <Select classnameprefix="select" options={SalutationOption} />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Full Name as in NRIC *</Form.Label>
            <Form.Control type="email" placeholder="Full Name" />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Date of Birth *</Form.Label>
            <Form.Control type="date" />
          </Col>
        </Row>
        <Row className="form-row">
          <Col xl={4} xs={12}>
            <Form.Label>ID Type *</Form.Label>
            <Select
              classnameprefix="select"
              options={[
                {
                  value: "NRIC",
                  label: "NRIC",
                },
                {
                  value: "FIN",
                  label: "FIN",
                },
              ]}
            />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>ID Number*</Form.Label>
            <Form.Control type="text" placeholder="ID Number" />
          </Col>
          <Col xl={4} xs={12}>
            <Form.Label>Gender</Form.Label>
            <Select
              classnameprefix="select"
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
