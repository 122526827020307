import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.css";

export function ImageTitle({ title }) {
  return (
    <>
      <LazyLoadImage
        className="img-title"
        src={require("./image-banner.webp").default}
      />
    </>
  );
}
