import React, { lazy, Suspense } from "react";
import BgImage from "../../assets/verify_page_photo.png";
import { Stack } from "@mui/material";
import LazyLoad from "../../Components/LazyLoading";
import { Helmet } from "react-helmet";

const FormVerification = lazy(() =>
  import("../../Components/Forms/FormVerification/index")
);

export function VerficationForm() {
  return (
    <>
      <Helmet>
        <title>Verification Form - Campus Impact</title>
      </Helmet>
      <Stack
        sx={{
          background: `url(${BgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "0px -35px",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          rigth: 0,
          bottom: 0,
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Suspense fallback={<LazyLoad />}>
          <FormVerification />
        </Suspense>
      </Stack>
    </>
  );
}
