import React from "react";
import { TitleSignupDetail } from "../TitleSignupDetail/index";
import { SignupPrefferedProgrammeForm } from "../Forms/SignupPrefferedProgrammeForm";
import { SignupAvailabilityForm } from "../Forms/SignupAvailabilityForm";

export function VolunteerPreferenceFormStep() {
  return (
    <>
      <TitleSignupDetail title={"Preferred Programme(s)"} />
      <div className="mt-2 mb-4">
        <SignupPrefferedProgrammeForm />
      </div>
      <TitleSignupDetail title={"Volunteering Availability"} />
      <div className="mt-1">
        <SignupAvailabilityForm />
      </div>
    </>
  );
}
